/* Leopard GridView */

.leopard-table-header {
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 18px;
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-lines) > td,
.dx-datagrid-rowsview .dx-selection.dx-row:hover:not(.dx-row-lines) > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td,
.dx-datagrid-rowsview .dx-selection.dx-row:hover:not(.dx-row-focused) > td {
    border-bottom: none;
    border-top: none;
}

.dx-datagrid .dx-sort-down:before,
.dx-datagrid .dx-sort-up:before {
    font-weight: bold;
}

.leopard-config-table th {
    font-size: 12px;
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
    padding: 15px 15px 15px 2px;
}

.leopard-config-table td {
    font-size: 12px;
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
}

.leopard-gridview-toolbar {
    width: 100%;
    text-align: right;
    padding-top: 2px;
    height: 30px;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
}

.leopard-chart-toolbar {
    width: 100%;
    text-align: right;
    padding-top: 2px;
    height: 30px;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
}

.leopard-timeline-toolbar {
    width: 100%;
    text-align: right;
    padding-top: 2px;
    height: 30px;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
}

.leopard-map-toolbar {
    width: 100%;
    text-align: right;
    padding-top: 2px;
    height: 30px;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
}

.leopard-formeditor-toolbar {
    width: 100%;
    text-align: right;
    padding-top: 2px;
    height: 30px;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
}

.leopard-htmlformbuilder-toolbar {
    width: 100%;
    text-align: right;
    padding-top: 2px;
    height: 30px;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
}

.leopard-gridview-advancedFilter {
    width: 100%;
    text-align: right;
    padding-top: 2px;
}

.leopard-hr-splitter {
    border: none;
    padding: 0;
    margin: 0;
}

.dx-master-detail-cell {
    padding: 5px 15px 5px 15px !important;
}

.leopard-accordion-title {
    font-size: 12px;
    font-weight: bold;
}

.drag-and-drop-selection-count {
    position: absolute;
    height: 30px;
    width: 30px;
    z-index: 99999;
    border-radius: 100px;
    margin-top: -32px;
    margin-left: -14px;
    text-align: center;
    line-height: 28px;
    font-size: 18px;
    display: none;
}

.leopard-top-menu-bar {
    height: 35px;
    width: 100%;
    z-index: 1;
    display: flex;
}

.leopard-userinfo-panel {
    width: 250px;
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
    z-index: 1000;
    position: absolute;
    right: 7px;
    top: 62px;
}

.leopard-loggedin-user-tag {
    float: right;
    position: absolute;
    top: 5px;
    cursor: pointer;
    font-size: 40px !important;
    right: 20px;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.m-nav .m-nav__separator {
    height: 0;
    margin: 15px 0;
    border: none !important;
}

.dx-tabpanel .dx-tab {
    width: 140px;
    font-size: 16px;
}

.leopard-top-logo {
    width: 160px;
    margin-left: 20px;
    margin-top: 6px;
    background-repeat: no-repeat;
    background-position: center;
}

.dx-button-content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 100%;
    max-height: 100%;
}

.leopard-logout-button {
    text-align: right;
}

.leopard-logout-button .dx-button-content {
    height: 26px;
    width: 90px;
    font-size: 12px;
    text-transform: uppercase;
}

.dx-button-mode-contained.dx-state-hover {
    border: none;
}

.dx-button {
    border: none;
}

.leopard-userinfo-category {
    font-weight: bold;
    font-size: 11px;
    margin-bottom: 7px;
}

.m-nav .m-nav__item > .m-nav__link .m-nav__link-text {
    font-size: 16px !important;
    padding: 3px 0 3px 0 !important;
}

.m-nav .m-nav__item > .m-nav__link {
    padding: 4px 0 !important;
    margin-left: 12px;
}

.leopard-welcome-text {
    position: absolute;
    right: 80px;
    top: 14px;
    font-size: 14px;
    text-align: right;
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
}

.leopard-topmenu-bar-left {
    height: 35px;
    width: 300px;
    min-width: 300px;
}

.leopard-topmenu-bar-hidebutton {
    height: 35px;
    width: 25px;
    min-width: 25px;
    font-size: 28px !important;
    padding-top: 3px;
    cursor: pointer;
}

.leopard-topmenu-bar-hidebutton.fa-caret-left {
    padding-left: 5px;
}

.leopard-topmenu-bar-hidebutton.fa-caret-right {
    padding-left: 8px;
}

.leopard-topmenu-bar-right {
    height: 35px;
    width: 100%;
}

.leopard-left-panel-container {
    height: 100%;
    width: 305px;
    min-width: 305px;
    overflow-y: hidden;
    overflow-x: hidden;
}

.leopard-leftmenu-category-container {
    height: 100%;
}

.leopard-right-panel-container {
    height: 100%;
    width: 100%;
    padding: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 5px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 500px;
}

.leopard-topmenu-search {
    width: 290px;
    margin-top: 5px;
    margin-left: 5px;
    border-radius: 0 !important;
    border: none !important;
}

.leopard-leftmenu-category {
    display: flex;
}

.leopard-leftmenu-item {
    font-size: 14px !important;
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
    padding-left: 0 !important;
    display: flex;
}

.leopard-leftmenu-item.hidden {
    display: none !important;
}

.leopard-leftmenu-item.selected {
    padding-left: 22px;
}

.leopard-leftmenu-item-text {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-top: auto;
    margin-bottom: auto;
}

.leopard-leftmenu-item-options {
    width: 20px;
    font-size: 20px !important;
    flex-grow: 0;
    flex-shrink: 0;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    display: none;
    margin-right: 9px;
    margin-left: 9px;
}

.leopard-leftmenu-item.selected .leopard-leftmenu-item-options {
    display: flex;
}

.dx-treeview-node .dx-treeview-item .dx-treeview-item-content {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.leopard-buttonlink .dx-button-content {
    text-transform: uppercase;
    font-weight: bold;
}

.leopard-gridview-admin-toolbar {
    padding-bottom: 5px;
}

.leopard-standard-button {
    font-size: 12px;
    text-transform: uppercase;
    border: none;
    border-radius: 0;
}

.leopard-navbar-title-text {
    font-size: 14px;
    text-transform: uppercase;
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
    line-height: 34px;
    padding-left: 20px;
}

.leopard-editgridview-title {
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
    font-weight: bold;
    font-size: 16px;
    margin-top: 10px;
    margin-left: 10px;
}

.leopard-please-wait-text {
    font-size: 12px;
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
    padding: 10px;
}

.dx-button-mode-contained.dx-state-active {
    text-transform: uppercase;
    border: none;
}

.dx-button-mode-contained.dx-state-focused {
    text-transform: uppercase;
    border: none;
}

.leopard-master-leftmenu-container {
    padding: 0;
    display: flex;
}

.leopard-gray-panel-container {
    padding: 0px 10px 10px 10px;
}

.dx-toast-content {
    min-width: 300px;
    max-width: 500px;
}

.dx-toast-message {
    font-size: 14px;
    font-weight: normal;
}

.dx-button-text {
    text-transform: uppercase;
}

.orange-arrow-top {
    width: 0px;
    height: 0px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    margin-top: -11px;
    margin-left: 206px;
    position: absolute;
}

.orange-arrow-left {
    width: 0px;
    height: 0px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    position: absolute;
    left: -11px;
    top: 37px;
}

.dx-datagrid-group-panel .dx-group-panel-item {
    margin-top: 1px;
}

.dataViewCategory_data-view {
    padding-right: 1px;
}

.leopard-leftmenu-category-addbutton {
    cursor: pointer;
    width: 40px;
    text-align: center;
    font-size: 20px !important;
    flex-grow: 0;
    flex-shrink: 0;
    margin-top: auto;
    margin-bottom: auto;
}

.leopard-leftmenu-category-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    cursor: pointer;
    display: flex;
    text-transform: uppercase;
    margin-top: auto;
    margin-bottom: auto;
}

.leopard-leftmenu-option-panel {
    border-top-style: solid;
    border-top-width: 2px;
    border-bottom-style: solid;
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
    border-bottom-width: 2px;
    height: 100%;
    position: absolute;
    left: 300px;
    z-index: 10;
    border-top: none;
    border-bottom: none;
    border-left-width: 5px;
    overflow: hidden;
    white-space: nowrap;
    width: 400px;
}

.leopard-option-panel-title {
    font-size: 18px;
    padding: 13px 13px 2px 20px;
}

.leopard-option-panel-description {
    font-size: 12px;
    padding: 0 13px 15px 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.leopard-option-panel-content {
    padding: 0px 20px 0 20px;
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
    font-size: 12px;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
}

.leopard-option-panel-block {
    padding: 7px 0px 7px 0px;
}

.leopard-option-panel-stepindex {
    font-size: 18px;
    font-weight: bold;
    padding-right: 7px;
}

.leopard-option-panel-steptext {
    font-size: 12px;
}

.leopard-option-panel-buttons {
    height: 50px;
    position: absolute;
    bottom: 0;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
}

.leopard-button {
    height: 26px;
    width: 90px;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
}

.leopard-button.auto-width {
    width: auto;
}

.leopard-button .dx-button-content {
    height: 26px;
    width: 90px;
    font-size: 12px;
    text-transform: uppercase;
}

.leopard-button.auto-width .dx-button-content {
    width: auto;
    height: 26px;
    font-size: 12px;
    text-transform: uppercase;
}

.leopard-button.auto-width.with-padding .dx-button-content {
    padding-left: 20px;
    padding-right: 20px;
}

.leopard-button.refresh-browser {
    width: auto;
}

.leopard-button.refresh-browser .dx-button-content {
    width: 100%;
}

.leopard-screen-cover {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 9;
    opacity: 0.8;
}

.leopard-screen-menucover {
    height: 100%;
    width: 300px;
    position: absolute;
    z-index: 9;
    opacity: 0.8;
}

.react-grid-item > .react-resizable-handle {
    width: 10px;
    height: 10px;
}

.leopard-templateview-cell-layout {
    height: 100%;
    box-sizing: border-box;
    width: 100%;
    padding-bottom: 0px;
    padding-top: 24px;
}

.leopard-templateview-cell-topbar {
    position: absolute;
    width: 100%;
    top: 0;
    clear: both;
    cursor: move !important;
    padding-left: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.leopard-moving-handler {
    font-size: 16px;
    cursor: move !important;
    margin-top: 5px !important;
    float: right;
    margin-right: 10px;
    text-align: right;
    margin-bottom: 5px;
    margin-left: 10px;
}

.leopard-templateview-cell-content {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.leopard-templateview-cell-content.hidescrollbar {
    overflow: hidden;
}

.leopard-moving-handler-text {
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    position: relative;
    top: 3px;
    white-space: nowrap;
    width: 80%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
}

.react-grid-item.react-grid-placeholder {
    opacity: 0.1;
}

.topmenu-arrow-indicator {
    width: 0px;
    height: 0px;
    border-left: 15px solid transparent;
    border-right: 0px solid transparent;
    position: absolute;
    right: 0;
}

.leopard-wait-panel {
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
    font-size: 12px;
    padding: 10px;
}

.leopard-box-panel {
    height: 100%;
}

.leopard-chart-container {
    border: 1px solid white;
    height: calc(100% - 32px) !important;
}

.leopard-settings-panel-title {
    padding: 5px 10px 10px 10px;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
}

.leopard-settings-panel-contentgroup {
    padding: 5px 10px 5px 10px;
    clear: both;
}

.leopard-settings-panel-content-label {
    padding-bottom: 5px;
}

.leopard-settings-panel-cell {
    padding: 10px 10px 15px 10px;
}

.leopard-largeloading-progress-text {
    padding-left: 15px;
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
    position: relative;
    top: -6px;
    font-size: 17px !important;
}

.leopard-largeloading-progress-icon {
    font-size: 30px;
    margin-top: 15%;
}

.leopard-preview-text {
    font-size: 11px;
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
    white-space: nowrap;
    padding: 0px 0px 10px 10px;
}

.leopard-empty-data-text {
    text-align: center;
    margin-top: 10%;
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
    font-size: 13px;
    font-weight: bold;
}

.leopard-settings-warning-text {
    margin-top: 5px;
    padding: 5px 10px 5px 10px;
}

.leopard-ui-disabled {
    opacity: 0.2;
}

.leopard-admintoolbar-loading {
    margin-right: 15px;
    margin-left: 20px;
    position: relative;
    top: 1px;
    display: none;
}

.leopard-gridview-dataloading {
    margin-right: 15px;
    margin-left: 8px;
    position: relative;
    top: 3px;
    float: left;
    display: none;
}

.leopard-ui-disabled {
    opacity: 0.2;
}

.leopard-admintoolbar-loading {
    margin-right: 15px;
    margin-left: 20px;
    position: relative;
    top: 1px;
    display: none;
}

.leopard-leftmenu-settings-section {
    margin-top: 10px;
    margin-bottom: 3px;
    clear: both;
}

.leopard-pagination-blocker {
    width: 100%;
    height: 28px;
    position: absolute;
    opacity: 0.5;
    z-index: 1;
}

.leopard-link-for-menuoption {
    font-size: 14px;
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
    line-height: 35px;
    padding-right: 15px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    padding-left: 20px;
}

.leopard-link-for-menuoption.disabled {
    opacity: 0.3;
}

.dx-fileuploader-input-wrapper .dx-fileuploader-button.dx-button.dx-button-normal {
    height: 26px;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    padding-right: 10px;
}

.dx-fileuploader-input-wrapper .dx-fileuploader-button.dx-button.dx-button-normal .dx-button-content {
    height: 26px;
    width: 95px;
    font-size: 12px;
    text-transform: uppercase;
}

.dx-fileuploader-show-file-list .dx-fileuploader-files-container {
    padding-top: 10px;
}

.dx-list-group-header {
    font-weight: bold;
    padding: 2px 5px 2px 5px;
}

.dx-scrollview-content .dx-list-group:first-of-type .dx-list-group-header {
    margin-top: 0px;
}

.dx-dropdownlist-popup-wrapper .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item-content {
    padding: 4px 4px;
}

.dx-datagrid-rowsview .dx-row.dx-group-row td {
    border-top: 2px solid;
    border-bottom: 1px solid;
}

.dx-layout-manager .dx-field-item:not(.dx-first-row) {
    padding-top: 2px;
}

.dx-datagrid-content .dx-row.dx-freespace-row.dx-column-lines .leopard_gridview_column_template {
    border-bottom: none !important;
}

.dx-scrollbar-horizontal .dx-scrollable-scroll {
    height: 8px !important;
}

.dx-scrollbar-horizontal.dx-scrollbar-hoverable {
    height: 8px !important;
}

.dx-scrollbar-vertical.dx-scrollbar-hoverable {
    width: 8px !important;
}

.dx-scrollbar-vertical .dx-scrollable-scroll {
    width: 8px !important;
}

.leopard-page-number {
    width: 32px;
    height: 26px;
    float: left;
    font-size: 12px;
    text-align: center;
    line-height: 23px;
    margin-right: 5px;
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
    cursor: pointer;
}

.leopard-page-container {
    height: 32px;
    padding-top: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.leopard-page-button {
    float: right;
    margin-right: 20px;
    text-decoration: none !important;
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    position: relative;
    top: 4px;
    margin-left: 5px;
}

.leopard-page-button-container {
    float: right;
    height: 25px;
    cursor: pointer;
}

.leopard-page-button-container.disabled {
    opacity: 0.2;
}

.leopard-page-infotext {
    float: right;
    padding-right: 15px;
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
    font-size: 12px;
    position: relative;
    top: 4px;
}

.leopard-field-attention-mark {
    font-weight: bold;
    display: none !important;
    border-radius: 100px;
    padding-left: 2px;
    font-size: 9px;
    position: relative;
    top: -3px;
    cursor: default;
}

.react-grid-item .leopard-page-container {
    padding-left: 5px;
    padding-right: 5px;
}

.react-grid-item .dx-datagrid-pager {
    padding-left: 5px;
    padding-right: 5px;
}

.leopard-no-whitespace {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.dx-header-row .leopard_gridview_column_template.disallow-sorting {
    cursor: default;
}

.dx-header-row .leopard_gridview_column_template.allow-sorting {
    cursor: pointer;
}

.leopard-field-attention-info {
    font-weight: bold;
    display: inline;
    border-radius: 100px;
    margin-right: 5px;
    font-size: 14px;
    position: relative;
    top: 0px;
    cursor: default;
}

.leopard-autorefresh-button_wrapper {
    float: left;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: -1px;
}

body {
    overflow: hidden;
}

.stiJsViewerFormButtonDefault {
    text-transform: uppercase;
    font-weight: normal;
    font-size: 12px;
    cursor: pointer;
}

.stiJsViewerFormButtonOver {
    text-transform: uppercase;
    font-weight: normal;
    font-size: 12px;
    cursor: pointer;
}

.stiJsViewerFormButtonSelected {
    text-transform: uppercase;
    font-weight: normal;
    font-size: 12px;
    cursor: pointer;
}

.stiDesignerPropertiesGroupHeaderButtonSelected_Mouse {
    font-weight: normal;
}

.leopard-listcontainer .dx-scrollview {
    border: 1px solid #dee1e3;
}

#LeopardReportViewer_ParametersPanelContainer table.stiJsViewerClearAllStyles td:not(.stiJsViewerClearAllStyles) {
    padding: 0 !important;
}

#LeopardReportDesigner {
    height: 100% !important;
}

.leopard-table-with-fixedcolumn table {
    position: relative;
    overflow: hidden;
    border-collapse: collapse;
}

.leopard-table-with-fixedcolumn thead {
    position: relative;
    display: block;
    overflow: visible;
}

.leopard-table-with-fixedcolumn thead th {
    height: 32px;
    text-transform: uppercase;
    min-width: 102px;
    text-align: center;
    font-size: 11px;
}

.leopard-table-with-fixedcolumn thead th:nth-child(1) {
    position: relative;
    z-index: 1;
    min-width: 150px;
    max-width: 150px;
    padding-left: 10px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
}

.leopard-table-with-fixedcolumn thead th.has-expanded-column:nth-child(1) {
    min-width: 220px;
    max-width: 220px;
}

.leopard-table-with-fixedcolumn tbody {
    position: relative;
    display: block;
    height: 400px;
    overflow: scroll;
}

.leopard-table-with-fixedcolumn tbody tr td:nth-child(1) {
    position: relative;
    display: block;
    z-index: 1;
    line-height: 26px;
    min-width: 150px;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
}

.leopard-table-with-fixedcolumn tbody tr td.has-expanded-column:nth-child(1) {
    min-width: 220px;
    max-width: 220px;
}

.leopard-table-with-fixedcolumn-container {
    overflow: hidden;
}

.leopard-config-table-header {
    text-align: center;
}

.leopard-photocallery-container .dx-scrollview-content .dx-item.dx-tile {
    border: none;
}

.leopard-tileviewitem-image {
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
}

.leopard-popupwindow-loading-progress {
    height: 100%;
    text-align: center;
    margin-top: 0;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10000;
    padding-top: 25%;
}

.leopard-popupwindow-loading-progress.icon-centered {
    display: flex;
    padding-top: 0;
}

.dx-datagrid .dx-command-edit .dx-link {
    padding-left: 4px;
    padding-right: 4px;
}

.leopard-configurepanel-title-button {
    top: 2px;
    cursor: pointer;
    position: relative;
    font-size: 20px;
}

.leopard-popup-window .dx-popup-title {
    border-bottom: none;
}

.leopard-photocallery-container-nophoto {
    display: none;
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
}

.leopard-photocallery-container {
    padding: 5px 0 5px 5px;
    height: 570px;
}

.leopard-photogallery-enlarged-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

[class*="_withPadding1px"] {
    padding-left: 1px !important;
    padding-right: 1px !important;
}

[class*="_withPadding2px"] {
    padding-left: 2px !important;
    padding-right: 2px !important;
}

[class*="_withPadding3px"] {
    padding-left: 3px !important;
    padding-right: 3px !important;
}

[class*="_withPadding4px"] {
    padding-left: 4px !important;
    padding-right: 4px !important;
}

[class*="_withPadding5px"] {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

[class*="_withPadding6px"] {
    padding-left: 6px !important;
    padding-right: 6px !important;
}

[class*="_withPadding7px"] {
    padding-left: 7px !important;
    padding-right: 7px !important;
}

[class*="_withPadding8px"] {
    padding-left: 8px !important;
    padding-right: 8px !important;
}

[class*="_withPadding9px"] {
    padding-left: 9px !important;
    padding-right: 9px !important;
}

[class*="_withPadding10px"] {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

[class*="_withPadding15px"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

[class*="_withPadding20px"] {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

[class*="_withPadding25px"] {
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.toolbar-warming-up-text {
    font-size: 12px;
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
    position: relative;
    top: -2px;
    left: 5px;
    display: none;
}

.leopard-menuoption-add-button {
    font-size: 20px;
    float: right;
    margin-top: 6px;
    cursor: pointer;
}

.leopard-error-message-override {
    height: 28px;
}

.leopard-menuoption-configbox-container {
    padding: 10px;
}

.dx-link.disabled {
    cursor: default !important;
    text-decoration: none;
}

.leopard-level-bar {
    text-align: center;
    margin-bottom: 10px;
}

.leopard-dashboard-level-text {
    float: right;
    text-transform: uppercase;
    font-size: 12px;
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
    padding: 2px 10px 0 0;
    line-height: 22px;
    position: absolute;
    right: 0;
    bottom: 2px;
}

.leopard-menuoption-splitter-container {
    padding: 0px 0 20px 0;
}

.leopard-empty-dataview-warning {
    width: 300px;
    margin-left: auto;
    font-size: 12px;
    margin-right: auto
}

.leopard-photogallery-data-view .dx-empty-message {
    padding: 10px;
}

.leopard-photogallery-data-view .dx-tile {
    border: none;
}

.dx-invalid-message .dx-overlay-content.dx-resizable.dx-invalid-message-content {
    max-width: 300px !important;
}

.leopard-popup-window.edit-form .dx-switch-on,
.leopard-popup-window.edit-form .dx-switch-off {
    line-height: 24px;
    font-size: 1em;
    font-weight: normal;
}

.leopard-popup-window.edit-form .dx-switch-container {
    height: 26px;
    border-radius: 3px;
}

.leopard-popup-window.edit-form .dx-switch-handle {
    height: 22px;
}

.leopard-popup-window.edit-form .dx-layout-manager .dx-label-h-align .dx-field-item-content .dx-switch {
    margin-top: 0;
    margin-bottom: 0;
}

.leopard-popup-window.edit-form .dx-item.dx-box-item {
    margin-top: 5px;
}

.leopard-popup-window.edit-form .dx-field-item-label.dx-field-item-label-location-top {
    margin-bottom: 3px;
}

.leopard-popup-window-editorpanel .dx-field-item-label.dx-field-item-label-location-top {
    margin-bottom: 3px;
    margin-top: 10px;
}

.leopard-textchanged-indicator {
    font-size: 12px;
    position: relative;
    padding-left: 5px;
}

.text-selection-disabled {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.without-scrollbar {
    overflow-y: hidden !important;
}

.topMenuItemSearchInput_MiniButton {
    cursor: default !important;
}

.leopard-topmenu-search .dx-texteditor-buttons-container {
    cursor: default !important;
}

.leopard-topmenu-search .dx-button-mode-contained {
    cursor: default !important;
}

.leopard-application-loading-cover {
    height: 100%;
    width: 100%;
    z-index: 10000;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.8;
    display: none;
}

.leopard-centre-box {
    position: absolute;
    top: 45%;
    left: 50%;
    margin-right: -50%;
    margin-left: 0;
}

.leopard-error-cell:hover {
    text-decoration: underline;
    cursor: pointer;
}

.leopard-popup-gridview-status {
    float: left;
    margin-top: -12px;
    position: absolute;
    margin-left: 10px;
    font-size: 11px;
}

.leopard-dataimport-popup-container {
    height: calc(100% - 50px);
}

.loading-progress-configurechart {
    margin-right: 15px;
    margin-left: 30px;
    position: relative;
    top: 5px;
    visibility: hidden;
}

.loading-progress-configurediagram {
    margin-right: 15px;
    margin-left: 30px;
    position: relative;
    top: 5px;
    visibility: hidden;
}

.loading-progress-configuretimeline {
    margin-right: 15px;
    margin-left: 30px;
    position: relative;
    top: 5px;
    visibility: hidden;
}

.loading-progress-configuremap {
    margin-right: 15px;
    margin-left: 30px;
    position: relative;
    top: 5px;
    visibility: hidden;
}

.loading-progress-configureeditor {
    margin-right: 15px;
    margin-left: 30px;
    position: relative;
    top: 5px;
    visibility: hidden;
}

.loading-progress-configureformeditor {
    margin-right: 15px;
    margin-left: 30px;
    position: relative;
    top: 5px;
    visibility: hidden;
}

.loading-progress-configuretabbedview {
    margin-right: 15px;
    margin-left: 30px;
    position: relative;
    top: 5px;
    visibility: hidden;
}

.leopard-popup-window-accesspanel {
    text-align: right;
    padding: 0px 10px;
    bottom: 20px;
    position: absolute;
    width: 100%;
    padding-right: 30px;
}

.leopard-popup-window-editorpanel {
    height: 100%;
    margin-bottom: 0;
    padding: 0px 10px;
    box-sizing: border-box;
    padding-bottom: 50px;
}

.dx-theme-generic-typography {
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
}

.dx-theme-generic-typography input,
.dx-theme-generic-typography textarea {
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
}

.dx-widget {
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
}

.dx-widget input,
.dx-widget textarea {
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
}

.dx-field {
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
}

.dx-field input,
.dx-field textarea {
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
}

.dx-overlay-wrapper {
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
}

.dx-overlay-wrapper input,
.dx-overlay-wrapper textarea {
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
}

.dx-menu-base {
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
}

.dx-menu-base input,
.dx-menu-base textarea {
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
}

.dx-datagrid-column-chooser {
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
}

.dx-datagrid-column-chooser input,
.dx-datagrid-column-chooser textarea {
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
}

.dx-pivotgrid .dx-ie .dx-pivotgrid-fields-area {
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
}

.dx-pivotgrid .dx-ie .dx-pivotgrid-fields-area input,
.dx-pivotgrid .dx-ie .dx-pivotgrid-fields-area textarea {
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
}

.dx-treelist-column-chooser {
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
}

.dx-treelist-column-chooser input,
.dx-treelist-column-chooser textarea {
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
}

.dx-switch-off, .dx-switch-on {
    font-weight: 100;
}

.leopard-attachment-widget-container .dx-tabpanel-container div.dx-multiview-wrapper {
    border-left: none;
    border-right: none;
    border-bottom: none;
}

.leopard-attachment-widget-container .dx-tabpanel-tabs .dx-tabs {
    border-left: none;
    border-right: none;
    border-bottom: none;
}

.leopard-attachment-widget-container .dx-tabs-wrapper .dx-tab-content {
    font-size: 12px;
}

.leopard-attachment-widget-container .dx-tabs-wrapper .dx-tab-content span {
    position: relative;
    top: -2px;
}

.leopard-add-autocomplete-blocker {
    height: 0px;
    width: 0px;
    position: absolute;
    top: 0px;
    left: 0px;
    border: none;
}

.leopard-chart-container svg.dxc-chart text {
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif !important;
}

.dx-datagrid-cell-updated-animation {
    -webkit-animation: 0s dx-datagrid-highlight-change;
    animation: 0s dx-datagrid-highlight-change;
}

.leopard-maximum-data-reached-warning {
    font-size: 14px;
    position: relative;
    top: 1px;
    display: none;
    animation: leopard-animation-framework-blinker 4s linear infinite;
}

.cc-update-notice-text {
    width: 100%;
    font-size: 16px;
    font-family: Arial;
    margin-top: 15%;
    position: absolute;
    z-index: 10001;
    text-align: center;
    display: none;
}

@keyframes leopard-animation-framework-blinker {
    50% {
        opacity: 0;
    }
}

.leopard-report-loading-spinner {
    position: absolute;
    z-index: 10;
    top: 90px;
    height: 100%;
    width: calc(100% - 10px);
}

.leopard-report-loading-spinner-built-in {
    position: absolute !important;
    z-index: 10 !important;
    top: 0 !important;
    left: 0 !important;
    height: 100%;
    width: 100%;
}

.leopard-report-loading-spinner-built-in .js_viewer_loader {
    margin-left: calc(50% - 39px);
    margin-top: calc(15% + 54px);
}

.mobile_designer_loader {
    margin-left: calc(50% - 200px);
    margin-top: 15%;
}

#RootWindowContainer.left-panel-hidden .mobile_designer_loader {
    margin-left: calc(50% - 0px);
}

#RootWindowContainer.left-panel-hidden .leopard-report-loading-spinner-built-in .js_viewer_loader {
    margin-left: calc(50% + 8px);
    margin-top: calc(15% + 8px);
}

#reportDesignerContent .leopard-report-loading-spinner {
    position: absolute;
    z-index: 100000;
    top: 0;
    height: 100%;
    width: 100%;
}

#reportDesignerContent .leopard-report-loading-spinner .mobile_designer_loader {
    margin-left: 50%;
    margin-top: 15%;
}

#LeopardReportViewerReportPanel {
    overflow-x: auto !important;
    overflow-y: hidden !important;
}

#LeopardReportViewer_ParametersPanelContainer tr.stiJsViewerClearAllStyles td input.stiJsViewerTextBox.stiJsViewerTextBoxDefault {
    width: 250px !important;
}

#LeopardReportViewer_ParametersPanelContainer tr.stiJsViewerClearAllStyles td input.stiJsViewerTextBox.stiJsViewerTextBoxOver {
    width: 250px !important;
}

#LeopardReportViewer_ParametersPanelContainer .stiJsViewerDropdownMenu .stiJsViewerClearAllStyles .stiJsViewerTextBox.stiJsViewerTextBoxDefault {
    width: 40px !important;
}

#LeopardReportViewer_ParametersPanelContainer .stiJsViewerDropdownMenu .stiJsViewerClearAllStyles .stiJsViewerTextBox.stiJsViewerTextBoxOver {
    width: 40px !important;
}

.leopard-photocallery-container-largephoto-text {
    text-align: center;
    position: relative;
    bottom: 35px;
    padding: 5px;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 100px;
}

.dashboard-settings-version, .dataview-settings-version {
    display: none;
}

.export-option-popup-link {
    cursor: pointer;
    padding: 5px;
}

.export-option-popup-link.disabled {
    opacity: 0.3;
}

.dx-state-disabled .dx-checkbox .dx-checkbox-icon,
.dx-state-disabled .dx-checkbox-text,
.dx-state-disabled.dx-checkbox .dx-checkbox-icon {
    opacity: 0.7;
}

.leopard-workspace-dataview-removebutton,
.leopard-workspace-dataview-addbutton {
    padding: 5px 0px 5px 10px;
}

.leopard-dropdown-item-doublelines {
    overflow: hidden;
}

.leopard-dropdown-item-doublelines .title {
    padding-top: 4px;
}

.leopard-dropdown-item-doublelines .desc {
    padding-bottom: 4px;
}

.leopard-workspace-dataview-addbutton-wrapper {
    text-align: right;
    position: relative;
    top: -10px;
}

.leopard-editpanel-protective-cover {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.8;
}

.leopard-static-toast {
    position: absolute;
    z-index: 100;
    bottom: 10px;
    right: 20px;
    display: none;
    border: 1px solid rgba(0, 0, 0, 0.3);
}

.leopard-static-toast.show {
    display: block;
}

.leopard-static-toast .toast-header {
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
    font-weight: 100;
}

.leopard-static-toast .toast-description {
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
    font-weight: 100;
}

.leopard-static-toast .close {
    text-shadow: none;
}

.leopard-import-list-item {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-top: auto;
    margin-bottom: auto;
    width: 200px;
    min-width: 200px;
}

#import_dataview_list_workspaces .dx-list-group:nth-child(2) .dx-list-item .dx-list-static-delete-button-container {
    display: none;
}

.leopard-action-group-popup {
    position: absolute;
    display: none;
    z-index: 100;
    margin-top: 15px;
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
    font-size: 12px;
    width: auto;
    padding-top: 5px;
    padding-bottom: 5px;
}

.leopard-action-group-popup .orange-arrow-top {
    margin-top: -15px;
}

.leopard-customcell-status {
    border-radius: 100px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 5px;
    text-transform: uppercase;
    margin-right: 5px;
    font-size: 10px;
    padding-bottom: 2px;
    padding-top: 1px;
}

.dx-overlay-wrapper:not(.dx-overlay-modal) .dx-overlay-content.dx-menu-base .dx-submenu .dx-menu-item-wrapper .dx-item-content.dx-menu-item-content {
    padding-top: 5px;
    padding-bottom: 5px;
}

.dx-overlay-wrapper:not(.dx-overlay-modal) .dx-overlay-content.dx-menu-base .dx-submenu {
    border-radius: 4px;
}

.leopard-splitter-control {
    height: 100%;
}

.leopard-splitter-control.with-admin-panel {
    height: calc(100% - 27px);
}

.leopard-splitter-control .splitter-layout {
    position: inherit;
}

.leopard-documenteditor-left-hand-side, .leopard-documenteditor-right-hand-side {
    padding: 10px 10px 10px 10px;
}

.leopard-documenteditor-right-hand-side {
    height: calc(100% - 40px);
}

.leopard-doc-editor-label {
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 5px;
}

.leopard-documenteditor-left-hand-side .MuiCardHeader-root {
    display: flex;
    padding: 24px 0 0 16px;
    align-items: center;
}

.leopard-documenteditor-left-hand-side .MuiTypography-h5 {
    font-size: 14px;
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
    font-weight: bold;
}

.leopard-documenteditor-left-hand-side .MuiCardContent-root {
    padding: 8px 0 16px 16px;
}

.leopard-documenteditor-left-hand-side .MuiPaper-elevation1 {
    box-shadow: none;
}

.leopard-documenteditor-left-hand-side .MuiPaper-rounded {
    border-radius: 0;
}

.leopard-splitter-control .panel-title {
    font-size: 16px;
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
    font-weight: bold;
    margin-bottom: 15px;
    width: 100%;
}

.leopard-splitter-control .data-previewer-container {
    height: 100%;
}

.leopard-documenteditor-left-hand-side .MuiTypography-root.MuiTypography-h6 {
    font-size: 14px;
    margin-top: 25px;
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
    font-weight: bold;
    text-transform: none;
}

.leopard-documenteditor-left-hand-side .MuiTableRow-root.MuiTableRow-head .MuiTableCell-head {
    padding-top: 10px;
    padding-bottom: 10px;
}

.leopard-documenteditor-left-hand-side .MuiGrid-root.MuiGrid-item .MuiButtonBase-root.MuiIconButton-root {
    padding-bottom: 0;
    position: relative;
    bottom: -8px;
}

.leopard-documenteditor-left-hand-side .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root.MuiTableCell-body {
    border-bottom: none;
    padding-bottom: 0;
    padding-top: 0;
}

.leopard-documenteditor-left-hand-side .MuiTableRow-root.MuiTableRow-head .MuiTableCell-root.MuiTableCell-head {
    font-size: 12px;
    font-weight: bold;
}

.MuiButton-textPrimary {
    border-radius: 0 !important;
    height: 26px !important;
    width: 102px !important;
}

.MuiDialogActions-root {
    display: block !important;
    text-align: center;
}

#alert-dialog-confirmdelete-title {
    padding: 0px 10px 0px 10px;
}

#alert-dialog-confirmdelete-description {
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
    font-size: 12px;
    margin-bottom: 4px;
}

.MuiTypography-body1 {
    letter-spacing: 0;
}

.MuiDialogContent-root {
    padding-left: 10px !important;
}

.MuiDialogTitle-root .MuiTypography-root.MuiTypography-h6 {
    font-size: 18px !important;
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif !important;
}

.MuiButton-textPrimary {
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif !important;
    font-size: 12px !important;
}

.leopard-documenteditor-left-hand-side .ValidationIcon-badge-1,
.leopard-documenteditor-left-hand-side .ValidationIcon-badge-2,
.leopard-documenteditor-left-hand-side .ValidationIcon-badge-3,
.leopard-documenteditor-left-hand-side .ValidationIcon-badge-4,
.leopard-documenteditor-left-hand-side .ValidationIcon-badge-5,
.leopard-documenteditor-left-hand-side .ValidationIcon-badge-6,
.leopard-documenteditor-left-hand-side .ValidationIcon-badge-7,
.leopard-documenteditor-left-hand-side .ValidationIcon-badge-8,
.leopard-documenteditor-left-hand-side .ValidationIcon-badge-9,
.leopard-documenteditor-left-hand-side .ValidationIcon-badge-10,
.leopard-documenteditor-left-hand-side .ValidationIcon-badge-11,
.leopard-documenteditor-left-hand-side .ValidationIcon-badge-12,
.leopard-documenteditor-left-hand-side .ValidationIcon-badge-13,
.leopard-documenteditor-left-hand-side .ValidationIcon-badge-14,
.leopard-documenteditor-left-hand-side .ValidationIcon-badge-15 {
    display: none;
}

.MuiFormHelperText-root.Mui-error {
    display: none;
}

.leopard-documenteditor-left-hand-side .ui-disabled-cover {
    height: 999999px;
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
}

.leopard-splitter-control .splitter-layout .layout-pane.layout-pane-primary.hide-scrollbar {
    overflow: hidden !important;
    opacity: 0.3;
}

.hide-scrollbar-horizontal-vertical {
    overflow: hidden !important;
}

.leopard-splitter-control .MuiTable-root .MuiTableCell-root.MuiTableCell-body .MuiTypography-body1 {
    font-size: 12px;
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
    padding: 10px;
}

.leopard-document-editor-container {
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
    font-size: 12px;
}

.leopard-loading-icon.savedocument {
    display: none;
}

.leopard-dataview-configbox .dx-item.dx-box-item {
    flex-grow: 0 !important;
    flex-shrink: 0 !important;
}

.leopard-settings-panel-contentgroup.optionalFields {
    display: none;
}

.dataview-parameters-label {
    float: left;
    margin-top: 3px;
    margin-right: 5px;
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
    font-size: 12px;
    margin-left: 10px;
}

.leopard-map-container {
    height: calc(100% - 60px);
    width: 100%;
}

.leopard-templateview-cell-layout .leopard-map-container {
    height: calc(100% - 40px);
    width: 100%;
}

.leopard-map-control {
    height: 100%;
    width: 100%;
}

.leopard-map-control .gm-style {
    font-size: inherit;
    font-family: inherit;
}

.leopard-map-control .gm-style .ol-attribution {
    bottom: 1em;
}

.leopard-map-control .gm-style .ol-attribution.ol-logo-only {
    bottom: 1em;
}

.leopard-map-marker-container {
    cursor: pointer;
}

.leopard-map-marker-text {
    position: absolute;
    top: 5px;
    z-index: 1;
    font-size: 12px;
    font-family: Arial;
    font-weight: normal;
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.leopard-map-marker-popover-contentcell-id {
    vertical-align: top;
    font-weight: bold;
    max-width: 140px;
    cursor: default;
}

.leopard-map-marker-popover-contentcell-id-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 10px;
    cursor: default;
}

.leopard-map-marker-popover-contentcell-value {
    max-width: 225px;
    cursor: default;
}

.leopard-map-marker-popover-contentcell-value-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: default;
}

.layout-popup-link {
    cursor: pointer;
    padding: 5px 5px 5px 10px;
    display: flex;
    width: 265px;
}

.layout-popup-link.readonly {
    width: 190px;
}

.layout-popup-link .option-text {
    width: 100%;
    min-width: 200px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.layout-popup-link .option-action-icon {
    width: 26px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
}

.leopard-button-dynamic-size {
    height: 26px;
    font-size: 12px;
    text-transform: uppercase;
    flex-shrink: 0;
    flex-grow: 0;
}

.layout-popup-panel-container {
    width: 100%;
    height: 50px;
    margin-top: 25px;
    display: flex;
}

.layout-popup-panel-container .leopard-gridview-dataloading {
    display: block !important;
    margin-right: auto;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    padding-bottom: 10px;
}

.leopard-dataview-retrievingdata-text {
    padding: 10px;
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
    font-size: 12px;
}

.layout-popup-link.disabled {
    opacity: 0.3;
}

.leopard-regex-tester-result-icon {
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 5px;
    margin-top: auto;
    margin-bottom: auto;
    height: 27px;
    display: flex;
    margin-right: 10px;
}

.leopard-regex-tester-result-icon .indicator {
    font-size: 17px;
    margin-top: auto;
    margin-bottom: auto;
}

.leopard-regex-tester-label {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    width: auto;
    flex-grow: 0;
    flex-shrink: 0;
}

.dx-popup-title .dx-closebutton {
    border-width: 0;
}

.leopard-regex-tester-container .dx-invalid-message div.dx-invalid-message-auto {
    display: none !important;
}

.leopard-document-editor-loading-progress {
    height: 100%;
    width: 100%;
    z-index: 10000;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.8;
}

.document-upload-popup-panel-master-container .dx-fileuploader-upload-button {
    display: none;
}

.document-upload-popup-panel-master-container .dx-fileuploader-file-container {
    border-bottom: none;
    padding-top: 0;
}

.document-upload-popup-panel-master-container .dx-fileuploader-button-container {
    display: none;
}

.document-upload-popup-panel-master-container .dx-fileuploader-files-container .dx-fileuploader-file {
    padding-top: 0;
}

.document-upload-popup-panel-master-container .dx-fileuploader-files-container {
    padding-top: 5px;
}

.document-upload-popup-panel-container {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    position: absolute;
    display: flex;
    opacity: 0.8;
    z-index: 1;
    display: none;
}

.document-upload-popup-panel-container.shown {
    display: flex;
}

.document-upload-popup-panel-container .leopard-gridview-dataloading {
    display: block !important;
    margin-right: auto;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
}

.leopard-splitter-control .splitter-layout .layout-splitter.hidden {
    width: 0 !important;
}

.leopard-messagewidget-messageto-text {
    width: 100%;
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
    font-size: 12px;
    margin-top: auto;
    margin-bottom: auto;
}

.leopard-formeditor-container {
    padding: 10px 20px 10px 0px;
}

.leopard-leftmenu-settingspanel-addbutton {
    cursor: pointer;
    text-align: center;
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: auto;
    font-size: 16px !important;
    float: right;
    margin-top: 7px;
    width: 20px;
}

.leopard-menupanel-splitter-container {
    position: relative;
    text-align: center;
    height: 4px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.leopard-menupanel-splitter-counter {
    position: absolute;
    left: calc(50% - 10px);
    bottom: -7px;
    height: 20px;
    width: 20px;
    border-radius: 100px;
}

.document-editor-notjson-warning {
    padding: 30px;
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;;
    font-size: 14px;
    text-align: center;
}

/*
.leopard-timeline-autoheight .dx-scheduler-cell-sizes-vertical {
    height: auto !important;
}

.leopard-timeline-resourceview-text {
    font-size: 12px;
    word-break: break-word;
    font-weight: normal;
}

.dx-popup-wrapper.dx-scheduler-appointment-tooltip-wrapper .dx-popup-content .dx-list-item {
    width: auto !important;
}

.dx-scheduler-appointment-tooltip-wrapper .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-hover,
.dx-scheduler-appointment-tooltip-wrapper .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-active {
    cursor: default !important;
}
*/

.leopard-leftmenu-devextreme-sortablecontainer {
    height: 100%;
    padding: 10px 0px 10px 0px;
}

.leopard-leftmenu-devextreme-sortablecontainer .leopard-leftmenu-treeview-directory {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 18px;
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
}

.leopard-leftmenu-devextreme-sortablecontainer .leopard-leftmenu-treeview-item {
    text-transform: none;
    font-size: 14px;
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
    min-height: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 5px 5px 5px 0;
}

.leopard-hidden-menufolder {
    margin-left: 5px;
    font-size: 14px;
}

.dx-field-item-content-wrapper .dx-field-item-help-text {
    font-size: 10px;
    margin-top: 3px;
}

.leopard-generic-hyperlink {
    margin-top: auto;
    margin-bottom: auto;
    cursor: default;
    text-decoration: underline;
}

.leopard-generic-hyperlink:hover {
    cursor: pointer !important;
}

.leopard-dummy_textbox_for_autofocus {
    height: 0;
    width: 0;
    border: none;
    font-size: 1px;
    padding: 0;
    margin: 0;
}

.leopard-photo-dragdrop-zone {
    height: 100%;
    width: 100%;
    display: flex;
}

.leopard-photo-display-thumbnail {
    height: calc(100% - 16px);
    width: calc(100% - 16px);
    object-fit: cover;
    position: absolute;
    margin: 3px;
    z-index: 0;
}

.leopard-photo-display-thumbnail.hidden {
    display: none;
}

.leopard-photo-dragdrop-zone-text.hidden {
    display: none;
}

.leopard-topmenu-indicator-number-container {
    height: 16px;
    width: 16px;
    border-radius: 3px;
    margin: auto;
    padding: 0;
    margin-left: -7px;
    display: none;
}

.leopard-topmenu-indicator-number-text {
    margin: auto;
    font-size: 10px;
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
}

.dataimportpopup-lockscreen-cover {
    height: calc(100% - 90px);
    width: calc(100% - 40px);
    opacity: 0.5;
    position: absolute;
    z-index: 2;
    display: none;
}

.leopard_tabbedview_viewer_container {
    height: 100%;
}

.leopard-tabbedview-tabpanel-container {
    height: calc(100% - 30px);
    width: 100%;
}

.leopard-gridview-control-within-tabview {
    height: calc(100% - 30px);
}

.leopard-gridview-control-within-tabview.optimized-pager {
    height: calc(100% - 66px) !important;
}

.leopard-tabbedview-tabpanel-container .leopard-autorefresh-button_wrapper {
    margin-top: 3px;
}

.leopard_tabbedview_viewer_container_disabled_cover {
    height: 30px;
    position: absolute;
    z-index: 5;
    width: 100%;
}

.leopard-templateview-cell-content .leopard-tabbedview-tabpanel-container {
    height: calc(100% - 10px) !important;
}

.leopard-templateview-cell-layout.html-form-builder .leopard-templateview-cell-content.hide-scrollbar-horizontal-vertical {
    overflow-y: auto !important;
}

.leopard-templateview-cell-layout.html-form-builder .formBuilderViewMode.form_viewmode {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.leopard-notification-panel-container {
    height: calc(100% - 85px);
    width: 500px;
    background-color: white;
    position: absolute;
    z-index: 10000;
    top: 85px;
    right: 0;
    display: none;
}

.leopard-notification-panel-container.shown {
    display: block;
}

.leopard-notification-item {
    display: flex;
    height: auto;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    padding-left: 10px;
    border-bottom: 1px dotted lightgrey;
}

.leopard-notification-item-icon {
    margin-bottom: auto;
    height: auto;
    font-size: 20px !important;
    margin-right: 10px;
    flex-shrink: 0;
    flex-grow: 0;
}

.leopard-notification-item-text-wrap {
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
}

.leopard-notification-item-text {
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
    font-size: 14px;
    margin-top: auto;
    margin-bottom: auto;
    height: auto;
    white-space: pre-wrap;
}

.leopard-notification-item-time {
    font-size: 10px;
    font-family: verdana;
    color: grey;
    padding-top: 2px;
}

.leopard-notification-panel-container .dx-list .dx-empty-message,
.leopard-notification-panel-container .dx-list-item-content {
    padding: 0 !important;
}

.leopard-notification-panel-container .no-message-container {
    display: none;
    height: 100%;
    width: 100%;
}

.leopard-notification-panel-container .no-message-container.shown {
    display: flex;
}

.leopard-notification-panel-container .no-message-text-wrap {
    margin: auto;
    text-align: center;
}

.leopard-notification-panel-container .no-message-icon {
    font-size: 25px;
}

.leopard-notification-panel-container .no-message-text {
    font-family: AP-Text-Regular, system, -apple-system, San Francisco, Roboto, Segoe UI, Arial, Helvetica Neue, Lucida Grande, sans-serif;
    font-size: 14px;
    margin-top: 5px;
}

.leopard-topmenu-bar-notification-wrapper {
    display: flex;
    cursor: pointer;
}

.leopard-topmenu-bar-notification {
    width: 25px;
    font-size: 15px;
    cursor: pointer;
    margin: auto;
}

.leopard-topmenu-bar-notification-wrapper.expanded {
    margin-right: 495px;
}

.leopard-topmenu-bar-notification-indicator {
    width: 25px;
    cursor: pointer;
    margin: auto;
    position: absolute;
    font-size: 8px;
    margin-top: 8px;
    margin-left: 4px;
    display: none;
}

.leopard-topmenu-bar-notification-indicator.shown {
    display: block;
}

.leopard-gridview-popup-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.leopard-gridview-popup-content {
    height: calc(100% - 45px);
    width: 100%;
}

.leopard-gridview-popup-buttons {
    height: 45px;
    width: 100%;
}

.leopard-generic-popup-form-layout {
    margin-top: 25px;
}

.leopard-generic-popup-form-layout .dx-form-group-with-caption.dx-form-group {
    margin-top: 20px;
}

.leopard-generic-popup-form-layout .dx-item.dx-box-item:first-of-type .dx-form-group-with-caption.dx-form-group {
    margin-top: 0;
}

.leopard-generic-popup-form-layout .dx-form-group-with-caption > .dx-form-group-content {
    padding-top: 8px;
}

.leopard-generic-popup-form-layout .dx-field-item-label-text {
    text-transform: capitalize;
}

.leopard-generic-form-container {
    width: 100%;
}

.leopard-formitem-no-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.leopard-sidebar-stripe-container {
    margin-top: auto;
    margin-bottom: auto;
    position: relative;
    right: 12px;
    display: flex;
}

.leopard-sidebar-stripe-content {
    color: white;
    background-color: var(--mainColor);
    padding-left: 7px;
    border-radius: 10px;
    width: 35px;
    height: 35px;
    text-align: center;
    display: flex;
}

.leopard-sidebar-stripe-number {
    margin: auto;
}

.leopard-attachablewidget-report.shown {
    display: flex !important;
}

.dx-toolbar .dx-toolbar-items-container .dx-toolbar-before .dx-item.dx-toolbar-item.dx-toolbar-label {
    max-width: 100% !important;
}